@font-face {
    font-family: 'sf-ui-display-medium';
    src: url('sf-ui-display-medium.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sf-ui-display-black';
    src: url('sf-ui-display-black.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sf-ui-display-bold';
    src: url('sf-ui-display-bold.otf');
    font-weight: normal;
    font-style: normal;
}